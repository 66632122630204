import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import styles from "../styles/ToggleTheme.module.scss";

function ToggleTheme() {
  const [darkTheme, setDarkTheme] = useState(false);

  // load the user-preferred theme
  useEffect(() => {
    setDarkTheme(window.__theme === "dark");
  }, []);

  // update the user-preferred theme when darkTheme changes
  useEffect(() => {
    window.__setPreferredTheme &&
      window.__setPreferredTheme(darkTheme ? "dark" : "light");
  }, [darkTheme]);

  return (
    <>
      <Helmet>
        <script>{`
          // Add dark / light detection that runs before GatsbyJS loads. Borrowed from overreacted.io
          (function() {
            window.__onThemeChange = function() {};
            function setTheme(newTheme) {
              window.__theme = newTheme;
              preferredTheme = newTheme;
              document.body.setAttribute("data-theme", newTheme);
              window.__onThemeChange(newTheme);
            }

            var preferredTheme;
            try {
              preferredTheme = localStorage.getItem("theme");
            } catch (err) {}

            window.__setPreferredTheme = function(newTheme) {
              setTheme(newTheme);
              try {
                localStorage.setItem("theme", newTheme);
              } catch (err) {}
            };

            var darkQuery = window.matchMedia("(prefers-color-scheme: dark)");
            darkQuery.addListener(function(e) {
              window.__setPreferredTheme(e.matches ? "dark" : "light");
            });

            setTheme(preferredTheme || (darkQuery.matches ? "dark" : "light"));

            // set transition after a slight delay to avoid
            // dark theme users to see a flash when visiting
            setTimeout(function() {
              document.body.style.transition = "color .6s, background-color .6s"
            }, 100);
          })();
        `}</script>
      </Helmet>
      <button
        aria-label="Toggle dark/light"
        onClick={() => setDarkTheme(!darkTheme)}
        className={styles.toggle}
      >
        {darkTheme && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-sun"
          >
            <circle cx="12" cy="12" r="5"></circle>
            <line x1="12" y1="1" x2="12" y2="3"></line>
            <line x1="12" y1="21" x2="12" y2="23"></line>
            <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
            <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
            <line x1="1" y1="12" x2="3" y2="12"></line>
            <line x1="21" y1="12" x2="23" y2="12"></line>
            <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
            <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
          </svg>
        )}
        {!darkTheme && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-moon"
          >
            <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
          </svg>
        )}
      </button>
    </>
  );
}

export default ToggleTheme;
