import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import styles from "../styles/Author.module.scss";

function Author({ showTitle }) {
  const data = useStaticQuery(graphql`
    query AuthorQuery {
      avatar: file(absolutePath: { regex: "/red-blob-icon.png/" }) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
          title
          description
        }
      }
    }
  `);

  const {
    author,
    title: siteTitle,
    description: siteDescription,
  } = data.site.siteMetadata;

  return (
    <div className={styles.author}>
      <Image
        alt={`${author}'s profile picture`}
        className={styles.author__image}
        fixed={data.avatar.childImageSharp.fixed}
      />

      {showTitle && <h1 className={styles.author__siteTitle}>{siteTitle}</h1>}

      <p className={styles.author__intro}>{siteDescription}.</p>

      {/* <p className={styles.author__links}>
        <a href="https://twitter.com/gogosse">@gogosse</a> sur Twitter
      </p> */}
    </div>
  );
}

export default Author;
