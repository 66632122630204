import React from "react";
import { Link } from "gatsby";
import _ from "lodash";
import styles from "../styles/PostTags.module.scss";

function PostTags({ tags, className }) {
  return (
    <div className={[styles.postTags, className].join(" ")}>
      {tags.map(tag => (
        <Link
          className={styles.postTags__link}
          key={tag}
          to={`/tags/${_.kebabCase(tag)}`}
        >
          <span>#</span> {tag}
        </Link>
      ))}
    </div>
  );
}

export default PostTags;
