import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styles from "../styles/Logo.module.scss";

function Logo() {
  const data = useStaticQuery(graphql`
    query LogoQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const { title: siteTitle } = data.site.siteMetadata;

  return (
    <Link className={styles.logo} to="/">
      <span className={styles.logo__text}>&larr; {siteTitle}</span>
    </Link>
  );
}

export default Logo;
