import React from "react";
import styles from "../styles/PostMeta.module.scss";

function PostMeta({ post, className }) {
  const {
    frontmatter: { date },
  } = post;

  return (
    <div className={[styles.postMeta, className].join(" ")}>
      Publié le {date}
    </div>
  );
}

export default PostMeta;
