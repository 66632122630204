import React from "react";
import ToggleTheme from "./ToggleTheme";
import Logo from "./Logo";
import styles from "../styles/Layout.module.scss";
import { Link } from "gatsby";

class Layout extends React.Component {
  render() {
    const { showLogo = true, showArchivesLink = false } = this.props;

    return (
      <>
        <header className={styles.header}>
          <div className={styles.header__left}>{showLogo && <Logo />}</div>

          <div className={styles.header__right}>
            <ToggleTheme />
          </div>
        </header>

        <main className={styles.main}>{this.props.children}</main>

        {showArchivesLink && (
          <section className={styles.archives}>
            Vous ne trouvez pas votre article préféré? Consultez les{" "}
            <Link to="/archives">archives</Link>.
          </section>
        )}

        <footer className={styles.footer}>
          <span className={styles.footer__links}>
            Généré par <a href="https://www.gatsbyjs.org">Gatsby</a>. Thème
            adapté de{" "}
            <a href="https://github.com/gridsome/gridsome-starter-blog">
              Gridsome starter blog
            </a>
            . Hébergé sur <a href="https://www.netlify.com/">Netlify</a>.
          </span>
        </footer>
      </>
    );
  }
}

export default Layout;
